import React, { useState } from "react";
import { callBackendAPI } from "../../../helpers/apiCalls";
import { useNavigate } from "react-router";
import { message } from "antd";
import { LoadingOutlined } from "@ant-design/icons";
import DeleteConfirmationModal from "./DeleteConfirmationModal";
import { useDispatch, useSelector } from "react-redux";
import {
  publisProductId,
  setPublishTrue,
} from "../../../redux/slices/publish-product-slice";
import { setTemplateView } from "../../../redux/slices/template-slice";
import DeleteProductModal from "../../MyProducts/ProductsStatusTable/DeleteProductModal";
import AllowPermisionModal from "../../AllowPermissionModal";

function convertHTMLToJSON(htmlString) {
  htmlString = htmlString?.replace("<ul>", "").replace("</ul>", "");
  var liItems = htmlString?.split("</li>");
  liItems = liItems?.filter((item) => item.trim() !== "");
  liItems = liItems?.map((item, index) => {
    return item?.replace("<li>", "");
  });
  return liItems;
}
const TopBar = ({
  customBadgeText,
  proTemplatePayload,
  productData: otherThanVariantsData,
  productPrice,
  showSalePrice,
  id,
  sectionsImages,
  step,
  setStep,
  propertiesLength,
  shipsFrom,
  productImages,
  loading,
  setLoading,
  setSaveOrPublish,
}) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [showPermissionModal, setShowPermissionModal] = useState({ open: false, scopes: `` });
  // redux fetcher
  const {
    productData,
    mediaImages,
    productBulletBenefits,
    totalEnabledVariants,
    totalAllowedVariants,
    editViewStyle,
    variantsArrangedIndexesObject,
  } = useSelector((store) => store.productVariants);
  const { staticMediaImages } = useSelector(
    (store) => store?.staticMediaImagesSlice
  );
  const { countryMethod } = useSelector((store) => store.shippingConfiguration);
  const { templateType } = useSelector((store) => store.templateSlice);
  const variantsArrangedActiveObject = useSelector(
    (state) => state.productVariants.variantsArrangedActiveObject // Update to match your slice
  );
  console.log('variantsArrangedActiveObject -- topbar: ', variantsArrangedActiveObject);


  console.log("variantsArrangedIndexesObject : ", variantsArrangedIndexesObject);

  const alertUser = (e) => {
    e.preventDefault();
    e.returnValue = "";
  };
  const updateProduct = async (key) => {
    // if the allowed variants limit exceed
    if (totalEnabledVariants > totalAllowedVariants) {
      message.error("Enabled variants exceed the limit allowed!");
      return;
    }

    // set alert if the user tries to refresh the page while saving the product
    window.addEventListener("beforeunload", alertUser);
    setSaveOrPublish(key);

    dispatch(publisProductId(id));
    const { productTemplate, product_variants } = productData.data;

    let updatedBulletBenefits;
    if (typeof productTemplate?.bulletBenefits === "string") {
      updatedBulletBenefits = convertHTMLToJSON(productTemplate.bulletBenefits);
    } else {
      updatedBulletBenefits = productTemplate?.bulletBenefits;
    }

    // updated Variant variant
    const updatedVariant = product_variants.map((variant) => ({
      ...variant,
      price: parseInt(productPrice.price) || 0,
      discounted_price: parseInt(productPrice.salePrice) || 0,
      enableSalePrice: showSalePrice,
    }));
    console.log("textKey:::", sectionsImages?.section6Image);

    // edit view style
    const editView = editViewStyle === "Dropdown" ? "dropdown" : "button";
console.log("productBulletBenefits",productBulletBenefits,  );

    // get product template data based on the template selection
    const templateDataForDifferenTemplates =
      templateType === "pro"
        ? {
            ...proTemplatePayload,
            // from productData
            salesText1: otherThanVariantsData.salesText1,
            salesText2: otherThanVariantsData.salesText2,
            salesText3: otherThanVariantsData.salesText3,
            salesTextHeadline1: otherThanVariantsData.salesTextHeadline1,
            salesTextHeadline2: otherThanVariantsData.salesTextHeadline2,
            salesTextHeadline3: otherThanVariantsData.salesTextHeadline3,
            productName: otherThanVariantsData.productName,
            shippingLink: otherThanVariantsData.shippingLink,
            productSubTitle: otherThanVariantsData.productSubTitle,
          }
        : templateType === "kids"
        ? {
            ...otherThanVariantsData,
          }
        : {
            ...otherThanVariantsData,
            bulletBenefits:   typeof productBulletBenefits !== "string"
            ?  productBulletBenefits.map((d) =>   d.heading  )  : convertHTMLToJSON(productBulletBenefits),
          };
    // for multiple variants

    const templateData = {
      ...productData.data,
      aliexpress_images: [...productImages, ...mediaImages],
      media_images: staticMediaImages,
      productTemplate: {
        ...templateDataForDifferenTemplates,
        variantDisplayStyle: editView,
        customBadge: customBadgeText,
      },
      sectionImages:
        templateType === "pro"
          ? {
              ...sectionsImages,
              section4Image: proTemplatePayload.textOnImageDetails.img,
            }
          : templateType === "kids"
          ? {
              section1Image: sectionsImages.mainTestimonialSectionImage,
              section2Image: sectionsImages.secondSectionImage,
              section3Image: sectionsImages.testimonialImage1,
              section4Image: sectionsImages.testimonialImage2,
              section5Image: sectionsImages.testimonialImage3,
              section6Image: sectionsImages.customSalesSectionImage,
              section7Image: sectionsImages.lastSectionImage,
              section8Image: sectionsImages.section8Image,
              section9Image: sectionsImages.section9Image,
              section10Image: sectionsImages.section10Image,
            }
          : sectionsImages,
    };
    console.log("templateData", templateData);

    // for single variant product
    const singleVariant = {
      ...productData.data,
      product_variants: updatedVariant,
      aliexpress_images: [...productImages, ...mediaImages],
      media_images: staticMediaImages,
      productTemplate: {
        ...templateDataForDifferenTemplates,
        customBadge: customBadgeText,
      },
      sectionImages:
        templateType === "pro"
          ? {
              ...sectionsImages,
              section4Image: proTemplatePayload.textOnImageDetails.img,
            }
          : templateType === "kids"
          ? {
              section1Image: sectionsImages.mainTestimonialSectionImage,
              section2Image: sectionsImages.secondSectionImage,
              section3Image: sectionsImages.testimonialImage1,
              section4Image: sectionsImages.testimonialImage2,
              section5Image: sectionsImages.testimonialImage3,
              section6Image: sectionsImages.customSalesSectionImage,
              section7Image: sectionsImages.lastSectionImage,
              section8Image: sectionsImages.section8Image,
              section9Image: sectionsImages.section9Image,
              section10Image: sectionsImages.section10Image,
            }
          : sectionsImages,
    };
    // decide whether single variant or multiple
    const finalData = propertiesLength > 1 ? templateData : singleVariant;

    setLoading(true);
    const endpoint =
      templateType === "pro"
        ? "template/edit_save_pro_template"
        : templateType === "kids"
        ? "template/kids_pets_edit_save_template"
        : "template/v1_edit_save_template";
    const method = "POST";
    const payload = {
      payload: {
        shipsFrom: shipsFrom ? shipsFrom : null,
        ...finalData,
        shippingConfiguration: countryMethod,
        variantsArrangedObject: variantsArrangedIndexesObject,
        variantsArrangedActiveObject: variantsArrangedActiveObject,
        templateType: templateType,
        action: key,
      },
    };
    console.log("create product payloadpayload:",payload);
    try {
      const response = await callBackendAPI(endpoint, method, {}, payload);
      console.log('endpoint 212: ', endpoint);
      if (response?.status) {
        setLoading(false);
        if (key === "publish") {
          const { data, shopifyUploadedImages } = response;
          console.log('shopifyUploadedImages: TopBAr 217', shopifyUploadedImages);
          let { imageUpload } = data;
          imageUpload = {
            ...imageUpload,
            shopify_ali_uploaded_images: shopifyUploadedImages,
          }
          console.log('imageUpload TopBAr 217: ', imageUpload);
          dispatch(setPublishTrue(imageUpload));
        }
        navigate("/");
      } else if (!response.status && response?.error === "ACCESS_DENIED") {
        setLoading(false);
        const match = response.message.match(/`([^`]*)`/);
        const result = match ? match[1] : null;
        setShowPermissionModal({ open: true, scopes: result});
      } else {
        message.error(
          response?.message ? response?.message : "Error publishing product!"
        );
        setTimeout(() => {
          navigate("/");
        }, 3000);
      }
    } catch (error) {
      setLoading(false);
      message.error(`Cannot update due to ${error.message}`, 2);
    } finally {
      window.removeEventListener("beforeunload", alertUser);
    }
  };

  const antIcon = (
    <LoadingOutlined
      style={{ fontSize: 24, color: "#6941c6", fontWeight: "bold" }}
      spin
    />
  );

  const showDeleteConfirmationModal = () => {
    if (productData?.productStatus === "import") {
      setIsModalVisible(true);
    } else {
      navigate("/");
    }
  };
  const openProductInAliExpress = () => {
    console.log(
      "datatatatatata",
      productData?.data?.productData?.aliexpress_product_id
    );

    window.open(
      `https://www.aliexpress.com/item/${productData?.data?.productData?.aliexpress_product_id}.html?`
    );
  };

  return (
    <>
      {showPermissionModal.open && (
        <AllowPermisionModal
          OpenModal={showPermissionModal}
          setOpModal={setShowPermissionModal}
        />
      )}
      <div className="top-bar-main-container">
        <div className="flex gap-2 items-center">
          <button
            onClick={showDeleteConfirmationModal}
            type="button"
            className="top-bar-main-container-white-button "
          >
            Cancel
          </button>
          <button
            onClick={openProductInAliExpress}
            type="button"
            className="top-container-right-btns-container-button  "
          >
            Open in AliExpress
          </button>
        </div>
        {propertiesLength > 1 ? (
          <div className="steps-buttons">
            <button
              onClick={() => setStep("Product template")}
              disabled={step === "Product template"}
              type="button"
              className={` ${
                step === "Product template"
                  ? "top-bar-main-container-middle-buttons"
                  : "top-bar-main-container-middle-buttons-override"
              }`}
            >
              <p>
                <b>Step 1 {"  "}</b>
                Product template
              </p>
            </button>
            <span className="top-bar-main-container-middle-buttons-vector"></span>

            <button
              onClick={() => setStep("Variant pricing")}
              disabled={step === "Variant pricing"}
              type="button"
              className={` ${
                step === "Variant pricing"
                  ? "top-bar-main-container-middle-buttons"
                  : "top-bar-main-container-middle-buttons-override"
              }`}
            >
              <p>
                <b>Step 2 </b>Variant pricing
              </p>
            </button>
            <span className="top-bar-main-container-middle-buttons-vector"></span>
            <button
              onClick={() => setStep("Shipping configuration")}
              disabled={step === "Shipping"}
              type="button"
              className={` ${
                step === "Shipping configuration"
                  ? "top-bar-main-container-middle-buttons"
                  : "top-bar-main-container-middle-buttons-override"
              }`}
            >
              <p>
                <b>Step 3 </b>
                Shipping configuration
              </p>
            </button>
          </div>
        ) : (
          <div className="steps-buttons">
            <button
              disabled
              type="button"
              className="  top-bar-main-container-middle-buttons"
            >
              Product template
            </button>
          </div>
        )}

        <div className="top-container-right-btns-container">
          <button
            onClick={() => updateProduct("draft")}
            type="button"
            className="top-bar-main-container-white-button"
            disabled={loading}
          >
            Save as draft
          </button>
          <button
            onClick={() => {
              if (propertiesLength > 1) {
                if (step === "Product template") {
                  setStep("Variant pricing");
                }
                if (step === "Variant pricing") {
                  setStep("Shipping configuration");
                }
                if (step === "Shipping configuration") {
                  updateProduct("publish");
                }
              } else {
                updateProduct("publish");
              }
            }}
            type="button"
            className="top-container-right-btns-container-button"
            disabled={loading}
          >
            {propertiesLength === 1
              ? "Publish"
              : propertiesLength > 1 && step === "Shipping configuration"
              ? "Publish"
              : "Next"}
          </button>
        </div>
      </div>
      {isModalVisible && (
        <DeleteConfirmationModal
          OpenModal={isModalVisible}
          setOpModal={setIsModalVisible}
          id={id}
        />
      )}
    </>
  );
};

export default TopBar;
