import React, { useEffect, useState } from "react";
import { Button, Layout, Menu, message } from "antd";
import leftIcon from "../../../assets/leftIcon.svg";
import manage from "../../../assets/manage.svg";
import home from "../../../assets/settings.svg";
import subscription from "../../../assets/dollar-icon.svg";
import products from "../../../assets/products.svg";
import logoutIcon from "../../../assets/logoutIcon.svg";
 import { Link, useLocation, useNavigate } from "react-router-dom";

import "./Wrapper.css";
import { useDispatch, useSelector } from "react-redux";
import { setStoreInformation } from "../../../redux/slices/auth-user-slice";
import { handleLogout } from "../../../Utilities/handleLogout";
const { Sider, Content } = Layout;

const Wrapper = (props) => {
  const dispatch = useDispatch()
  const { email } = useSelector(store => store.authUser)
  const [collapsed, setCollapsed] = useState(false);
  const location = useLocation();
  const navigate = useNavigate()
  useEffect(() => {
    dispatch(setStoreInformation({
      shop_owner: localStorage?.getItem("shop_owner"), email: localStorage?.getItem("email"),
      shopifyConnectionDetails: { connected: localStorage?.getItem("shopifyConnected"), name: localStorage?.getItem("shopName") }
    }))

  }, [])
  let defaultSelectedKey;
  if (location.pathname === "/") {
    defaultSelectedKey = "1";
  } else if (location.pathname === "/orders") {
    defaultSelectedKey = "2";
  } else if (location.pathname === "/settings") {
    defaultSelectedKey = "3";
  }else if (location.pathname === "/subscriptions") {
    defaultSelectedKey = "4";
  }
  const menuItems = [
    {
      key: "1",
      icon: <img src={products} className="menu-icon" />,
      label: "My Products",
      to: "/",
    },

    {
      key: "2",
      icon: <img src={manage} className="menu-icon" />,
      label: "Manage Orders",
      to: "/orders",
    },
    {
      key: "3",
      icon: <img src={home} className="menu-icon" />,
      label: "Settings",
      to: "/settings",
    },
    {
      key: "4",
      icon: <img src={subscription} className="menu-icon" />,
      label: "My Plan",
      to: "/subscriptions",
    },
  ];

  const handleCopyRightClick = () => {
    window.open('/privacy', '_blank');
  }
  return (
    <Layout style={{ minHeight: "100vh" }}>
      <Sider
        trigger={null}
        collapsible
        collapsed={collapsed}
        collapsedWidth={80}
        width={251}
        className="sider-container"
        style={{ backgroundColor: "#fff", borderRight: "2px solid #e6e6e6", height: '100vh', position: 'fixed' }}
      >
        <div>
          <div
            className="top-header-container"
            style={{ justifyContent: collapsed ? "center" : "space-between" }}
          >
            {!collapsed ? <h3>Sofia</h3> : ""}
            <Button
              type="text"
              icon={
                collapsed ? (
                  <img src={leftIcon} style={{ transform: "rotate(180deg)" }} />
                ) : (
                  <img src={leftIcon} />
                )
              }
              onClick={() => setCollapsed(!collapsed)}
              style={{ fontSize: "16px", width: 24, height: 24 }}
            />
          </div>

          <Menu
            theme="light"
            mode="inline"
            defaultSelectedKeys={[defaultSelectedKey]}
          >
            {menuItems.map((item) => (
              <Menu.Item key={item.key} icon={item.icon}>
                <Link to={item.to}>{item.label}</Link>
              </Menu.Item>
            ))}
          </Menu>
        </div>

        <div className="sider-lower-container">
          <div
            onClick={handleLogout}
            style={{
              justifyContent: collapsed ? "center" : "",
              paddingLeft: !collapsed ? "10px" : "0px",
              cursor: "pointer",
            }}
            className="logout-row"
          >
            <img src={logoutIcon} alt="" />
            {!collapsed ? (
              <span className={!collapsed ? "collapsed" : ""}>Sign Out</span>
            ) : (
              ""
            )}
          </div>

          <div className="profile-container">
            {!collapsed ? (
              <div className="profile-content">
                <span
                  className={`profile-name ${!collapsed ? "collapsed" : ""} ${!email ? "notConnected" : ""}`}
                >
                  {email ? email : "Store Not Connected!"}
                </span>
              </div>
            ) : (
              ""
            )}
          </div>
        </div>
      </Sider>
      <Layout>
        <Content>
          <div className={`wrapperChild mb-[150px] w-full   ${collapsed ? '!pl-[101px]' : '!pl-[251px]'}`}>
            <div className="pl-[21px]">
              {props.children}
            </div>
            <div
              style={{ borderTop: '2px solid #e6e6e6' }}
              className="fixed bottom-0 mt-5 bg-white w-full flex flex-col justify-center items-center p-[17px]">
              <p onClick={handleCopyRightClick} className="footer-text">
                ©  Copyright <b>Sofia</b> . All Rights Reserved
              </p>
              <p onClick={handleCopyRightClick} className="footer-text">
                Privacy Policy
                </p>
            </div>
          </div>
        </Content>
      </Layout>
    </Layout>
  );
};

export default Wrapper;
