import React, { useEffect, useState } from "react";
import "./ProductEditView.css";
import "./PetsKidsEditView.css";
import TopBar from "./TopBar";
import PrductGallery from "./PrductGallery";
import { useParams } from "react-router-dom";
import { convertResponseJsonToObject } from "../../Utilities/ConvertInObjectForm";
import { Spin, Alert } from "antd";
import { LoadingOutlined } from "@ant-design/icons";

import VariantPricingTable from "../VariantPricingTable";
import ShippingConfigurationDetails from "../ShippingConfigurationDetails";
import { useDispatch, useSelector } from "react-redux";

import getPriceOfUniquProperties from "../../Utilities/productVariantsHandlers/getPriceOfUniquProperties";
import useVariantsData from "../hooks/useVariantsData";
import varinatsShippedFrom from "../../Utilities/productVariantsHandlers/VariantsShippedFrom";
import SavePublishModal from "./TopBar/SavePublishModal";
import { setArragedVariantsData } from "../../redux/slices/product-variants-slice";
import { setStaticMediaImagesInStateimport } from "../../redux/slices/static-media-images-slice";
import ProductVariantsSection from "./ProductVariantsSection/ProductVariantsSection";
import PetsKidsTemplateRightSection from "./RightSection/PetsKidsTemplateRightSection";
import PetsKidsTemplatePriceHeading from "../PetKidsTemplateComponents/PetsKidsTemplatePriceHeading";
import KidsPetsTemplateSectionUnderPriceAndHeading from "../PetKidsTemplateComponents/KidsPetsTemplateSectionUnderPriceAndHeading";
import PetsKidsTemplateBuyersReviews from "../PetKidsTemplateComponents/PetsKidsTemplateBuyersReviews";
import PetsKidsTemplateDescribeProduct from "../PetKidsTemplateComponents/PetsKidsTemplateDescribeProduct";
import PetsKidsTemplateSteps from "../PetKidsTemplateComponents/PetsKidsTemplateSteps";
import PetsKidsTemplateServices from "../PetKidsTemplateComponents/PetsKidsTemplateServices";
import PetsKidsTemplateSeventhSection from "../PetKidsTemplateComponents/PetsKidsTemplateSeventhSection";
import PetsKidsTemplateLastSalesSection from "../PetKidsTemplateComponents/PetsKidsTemplateLastSalesSection";
import PetsKidsTemplateFAQ from "../PetKidsTemplateComponents/PetsKidsTemplateFAQ";
import { Upload, message, Image } from "antd";
import { PlusOutlined } from "@ant-design/icons";
import { callBackendAPI } from "../../helpers/apiCalls";

const UploadImage = ({
  fileList,
  setFileList,
  previewImage,
  setPreviewImage,
  onFileChange,
}) => {
  const { id } = useParams();

  const handlePreview = (file) => {
    setPreviewImage(file.thumbUrl || file.url);
  };

  const handleChange = ({ file, fileList: newFileList }) => {
    const isValid =
      file.type === "image/png" ||
      file.type === "image/jpeg" ||
      file.type === "image/svg+xml";

    if (!isValid) {
      message.error("Only PNG, JPEG, or SVG files are allowed!");
      return;
    }

    setFileList(newFileList);

    // Notify parent about file change
    if (onFileChange) {
      onFileChange(newFileList);
    }
  };

  const uploadButton = (
    <div>
      <PlusOutlined />
      <div>Upload</div>
    </div>
  );

  const readFileAsBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = () => {
        if (reader.result) {
          const base64String = reader.result.toString().split(",")[1];
          resolve(base64String);
        } else {
          reject(new Error("Failed to read file"));
        }
      };
      reader.onerror = () => {
        reject(reader.error);
      };
      reader.readAsDataURL(file);
    });
  };

  const uploadImages = async (options) => {
    const { file, onSuccess, onError } = options;

    try {
      // Convert file to Base64
      const base64Image = await readFileAsBase64(file);

      // Create payload
      const endpoint = "template/v1-upload-images-r2";
      const method = "POST";
      const payload = {
        payload: {
          id: parseInt(id),
          images: [{ image: base64Image, fileType: file.type }],
        },
      };

      // Call backend API
      const response = await callBackendAPI(endpoint, method, {}, payload);
      if (response?.status) {
        const imageUrl = response.imageUrl;
        console.log('imageUrl: ', imageUrl);
        message.success("Image uploaded successfully!", 2);
        onSuccess("Upload successful"); // Notify Ant Design about success
      } else {
        throw new Error("Upload failed");
      }
    } catch (error) {
      console.error("Upload error:", error);
      message.error(`Cannot upload due to ${error.message}`);
      onError(error); // Notify Ant Design about the error
    }
  };

  return (
    <div>
      <Upload
        listType="picture-card"
        onChange={handleChange}
        onPreview={handlePreview}
        fileList={fileList}
        customRequest={uploadImages} // Handle custom upload
      >
        {fileList.length < 1 && uploadButton}
      </Upload>

      {/* Preview the selected image */}
      {previewImage && (
        <Image
          src={previewImage}
          alt="Preview"
          style={{ marginTop: "10px", maxHeight: "200px" }}
        />
      )}
    </div>
  );
};


const details = `Lorem Ipsum is simply dummy text of the printing and 
typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s,
 when an unknown printer took a galley of type and scrambled it to make a type specimen book`;
const img = "https://miro.medium.com/v2/resize:fit:2400/0*hDAyhnOx767w5qma.jpg";

const dummyData = {
  productName: "Ultimate Comfort Chair",
  bulletBenefits: [
    "Ergonomic design for superior support.",
    "Breathable fabric for all-day comfort.",
    "Lightweight and easy to move.",
    "Adjustable height and recline options.",
  ],

  testimonial: "This chair has revolutionized how I work. No more back pain!",
  testimonialName: "Jordan Smith",
  testimonialWriters: [
    {
      name: "",
      age: "",
    },
    {
      name: "",
      age: "",
    },
    {
      name: "",
      age: "",
    },
    {
      name: "",
      age: "",
    },
  ],

  salesTextHeadline: "Transform Your Workspace Today!",
  salesText:
    "Our ergonomic chair ensures comfort, productivity, and style at an unbeatable price.",

  testimonialHeadline: "Customer Love",
  testimonialSubHeadline: "See what our happy customers are saying.",
  testimonialSubHeadline2: "See what our happy customers are saying.",

  testimonial2:
    "The best chair I've ever owned. It looks great and feels even better.",
  testimonialName2: "Chris Taylor ",
  testimonial3:
    "Perfect for long work hours. Highly recommended for anyone working from home.",
  testimonialName3: "Jamie Morgan",
  testimonial4:
    "Comfortable, stylish, and affordable. What more could you ask for?",
  testimonialName4: "Pat Riley",
  testimonialsAge1: "30",
  testimonialsAge2: "45",
  testimonialsAge3: "38",

  questionHeadline: "String",
  answerBenefits: [
    {
      title: "  top left",
      description: details,
    },
    {
      title: "  bottom left",
      description: details,
    },
    {
      title: "  top right",
      description: details,
    },
    {
      title: "  bottom right",
      description: details,
    },
  ],
  howToUseTitle: "String",
  howToUseSteps: [
    {
      title: "Step 1",
      description: "Slide your feet into the SummerStride™️ shoes.",
    },
  ],
  recommendedHeadline: "String",
  recommendedBullets: ["String", "string", "String"],
  saleHeadline: "String",
  saleSubSections: [
    {
      question: "String",
      answerTitle: "String",
      answerText: "String",
    },
    {
      question: "String",
      answerTitle: "String",
      answerText: "String",
    },
    {
      question: "String",
      answerTitle: "String",
      answerText: "String",
    },
    {
      question: "String",
      answerTitle: "String",
      answerText: "String",
    },
  ],
  frequentlyAskedQuestions: [
    {
      question: "String1",
      answer: "String1",
    },
    {
      question: "String2",
      answer: "String2",
    },
  ],
  moneybackGuaranteeHeadline: "String",
  moneybackGuaranteeText: "String",
};

export const dummySectionImages = {
  mainTestimonialSectionImage:
    "//sibr60mujk9m04ek-61932142812.shopifypreview.com/cdn/shop/files/Se4058822c38e45bcaec4c7d79846bd08n_400x.webp?v=1729518519",

  secondSectionImage:
    "//sibr60mujk9m04ek-61932142812.shopifypreview.com/cdn/shop/files/Se4058822c38e45bcaec4c7d79846bd08n_400x.webp?v=1729518519",
  testimonialImage1:
    "//sibr60mujk9m04ek-61932142812.shopifypreview.com/cdn/shop/files/Se4058822c38e45bcaec4c7d79846bd08n_400x.webp?v=1729518519",
  testimonialImage2:
    "//sibr60mujk9m04ek-61932142812.shopifypreview.com/cdn/shop/files/Se4058822c38e45bcaec4c7d79846bd08n_400x.webp?v=1729518519",
  testimonialImage3:
    "//sibr60mujk9m04ek-61932142812.shopifypreview.com/cdn/shop/files/Se4058822c38e45bcaec4c7d79846bd08n_400x.webp?v=1729518519",
  customSalesSectionImage:
    "//sibr60mujk9m04ek-61932142812.shopifypreview.com/cdn/shop/files/Se4058822c38e45bcaec4c7d79846bd08n_400x.webp?v=1729518519",
  lastSectionImage:
    "//sibr60mujk9m04ek-61932142812.shopifypreview.com/cdn/shop/files/Se4058822c38e45bcaec4c7d79846bd08n_400x.webp?v=1729518519",
};

const PetsKidsTemplateEditView = () => {
  const dispatch = useDispatch();

  // redux states fetcher
  const {
    productData: Data,
    selectedProperties,
    totalEnabledVariants,
    totalAllowedVariants,
    globallyDisabledProperties,
    variantsArrangedIndexesObject: uniqueValuesObject,
  } = useSelector((store) => store.productVariants);


  // hook called
  const {
    propertiesArray,
    uniqueValuesObject: uniqueValue,
    newImagesObject,
    imageKey,
  } = useVariantsData(Data);

  const { data } = Data;
  const { id } = useParams();
  const [loading, setLoading] = useState(false);
  const [productData, setProductData] = useState({});
  const [productPrice, setProductPrice] = useState({ price: 0, salePrice: 0 });
  const { product_variants } = data;
  const [propertiesLength, __] = useState(product_variants?.length);
  const [showSalePrice, setShowSalePrice] = useState(false);
  const [productImages, setProductImages] = useState([]);
  const [editsectionImageUrl, setEditsectionImageUrl] = useState("");
  const [sectionsImages, setSectionsImages] = useState({});
  const [aliexpressImages, setAliexpressImages] = useState([]);
  const [variantView, setVariantViewChange] = useState("Dropdown");
  const [step, setStep] = useState("Product template");
  const [shipsFrom, setShipsFrom] = useState();
  const [groupBy, setGroupBy] = useState(0);
  const [customBadgeText, setCustomBadgeText] = useState({
    text1: "Free Shipping",
    text2: "60 days risk free",
    text3: "Easy Returns",
  });

  const [selectedProperty, setSelectedProperty] = useState([]);
  const [savingLoading, setSavingLoading] = useState(false);
  const [saveOrPublish, setSaveOrPublish] = useState("");

  const [fileList1, setFileList1] = useState([]);
  const [fileList2, setFileList2] = useState([]);
  const [fileList3, setFileList3] = useState([]);
  const [previewImage1, setPreviewImage1] = useState("");
  const [previewImage2, setPreviewImage2] = useState("");
  const [previewImage3, setPreviewImage3] = useState("");

  // cache images
  const [imageCache, setImageCache] = useState({});
  const [isImgCachingLoading, setIsImgCachingLoading] = useState(true);

  const handleCustomBadgeChange = (e, key) => {
    console.log('e:sssss ', e);
    setCustomBadgeText({ ...customBadgeText, [key]: e.target.value });
  };


  const preloadImages = async (srcArray) => {
    const cache = {};

    const promises = srcArray.map((src) => {
      return new Promise((resolve, reject) => {
        const img = new Image();
        img.src = src;

        img.onload = () => {
          cache[src] = img;
          resolve(img);
        };

        img.onerror = (error) => {
          console.error(`Error loading image: ${src}`, error);
          reject(error);
        };
      });
    });

    await Promise.all(promises);
    return cache;
  };

  useEffect(() => {
    if (Object.keys(uniqueValuesObject).length === 0) {
      dispatch(setArragedVariantsData(uniqueValue));
    }
  }, [uniqueValue]);

  useEffect(() => {
    const variantImages = imageKey
      ? Object.values(newImagesObject[imageKey])
      : [];
    const sectionImgArray = Object.values(sectionsImages) || [];
    const allImages = [...variantImages, ...sectionImgArray, ...productImages];
    const allUniqueImages = [...new Set(allImages)]; // Remove duplicates

    // Cache all images
    const preloadAndCacheImages = async (allUniqueImages) => {
      try {
        // setIsImgCachingLoading(true);
        const cache = await preloadImages(allUniqueImages);
        setImageCache(cache);
        setIsImgCachingLoading(false);
      } catch (error) {
        console.error("Error preloading images:", error);
        setIsImgCachingLoading(false);
      }
    };
    preloadAndCacheImages(allUniqueImages);
  }, [sectionsImages, productImages]);

  useEffect(() => {
    if (!(product_variants?.length <= 1)) {
      const filtered = varinatsShippedFrom(product_variants, shipsFrom);
      const priceFromFiltered =
        filtered.length > 0 ? filtered : product_variants;
      const variant = getPriceOfUniquProperties(
        selectedProperties,
        Data,
        priceFromFiltered,
        shipsFrom
      );
      setProductPrice({
        price: variant?.price,
        salePrice: variant?.discounted_price,
      });
      setShowSalePrice(variant?.enableSalePrice);
    }
  }, [Data, selectedProperties]);

  useEffect(() => {
    if (Object.keys(uniqueValuesObject).length !== 0) {
      let ind = 0;
      const startingSelection = propertiesArray?.map((select) => {
        uniqueValuesObject[select]?.map((pro, i) => {
          const disable =
            globallyDisabledProperties[select]?.includes(pro) ?? false;
          if (!disable) {
            ind = i;
            return;
          }
        });
        return { [select]: uniqueValuesObject[select][ind] };
      });

      const startSelectionObject = startingSelection?.reduce((acc, item) => {
        Object.entries(item).forEach(([key, value]) => {
          acc[key] = value;
        });

        return acc;
      }, {});

      setSelectedProperty(startSelectionObject);
    }
  }, [Data, uniqueValuesObject]);

  useEffect(() => {
    setShipsFrom(Data?.shipsFrom);
    setProductData({
      ...convertResponseJsonToObject(data.productTemplate),
      ["productStatus"]: Data.product_status,
      // ...dummyData,
    });

    console.log("data.sectionImages : ", data.sectionImages);

    setSectionsImages({
      mainTestimonialSectionImage: data.sectionImages.section1Image,
      secondSectionImage: data.sectionImages.section2Image,
      testimonialImage1: data.sectionImages.section3Image,
      testimonialImage2: data.sectionImages.section4Image,
      testimonialImage3: data.sectionImages.section5Image,
      customSalesSectionImage: data.sectionImages.section6Image,
      lastSectionImage: data.sectionImages.section7Image,
      section8Image: data.sectionImages.section8Image,
      section9Image: data.sectionImages.section9Image,
      section10Image: data.sectionImages.section10Image,

      // ...dummySectionImages                //dummy adding here
    });
    setProductImages(data.aliexpress_images);
    setAliexpressImages(data.aliexpress_images);
    // setProductImages(data.media_images);
    // setAliexpressImages(data.media_images);

    dispatch(setStaticMediaImagesInStateimport(data.media_images));

    if (product_variants?.length <= 1) {
      setShowSalePrice(product_variants[0]?.enableSalePrice);
      setProductPrice({ price: product_variants[0]?.price });
      if (product_variants[0]?.enableSalePrice) {
        setProductPrice({
          price: product_variants[0]?.price,
          salePrice: product_variants[0]?.discounted_price,
        });
      }
    }
  }, []);

  const handleSectionEditChange = (keys) => {
    const allFalse = Object.keys(sectionClick).reduce((acc, curr) => {
      acc[curr] = false;
      return acc;
    }, {});

    const newSectionClick = { ...allFalse };
    keys.forEach((key) => {
      newSectionClick[key] = true;
    });

    setSectionClick(newSectionClick);
  };

  const hanldeCollapseContentClick = ({ keys, index }) => {
    setCollapseIndex(index);
    handleSectionEditChange(keys);
  };

  const [labelName, setLabelName] = useState(
    propertiesArray.reduce((acc, prop) => {
      acc[prop] = prop;
      return acc;
    }, {})
  );

  const [textOnImageDetails, setTextOnImageDetails] = useState({
    title: "60-Day Risk-Free Guarantee",
    details:
      "Not satisfied with your product? Return it to us, risk-free in 60 days. That’s how confident we are that you’ll like the product.",
    img: img,
  });

  const [collapseIndex, setCollapseIndex] = useState(null);

  const antIcon = (
    <LoadingOutlined
      class="custom-spin-icon"
      style={{ fontSize: "50px" }}
      spin
    />
  );
  const [sectionClick, setSectionClick] = useState({
    productName: false,
    shippingLink: false,
    featureUnderProductNameSection: false,
    testimonialMainSection: false,
    shippingRiskFreeReturns: false,
    salesTextHeadline1Section: false,
    salesText1Section: false,
    testimonialsHeadingSection: false,
    testimonialsSubHeadingSection: false,
    testimonial1Section: false,
    testimonial2Section: false,
    testimonial3Section: false,
    questionHeadlineSection: false,
    answerBenefitsTopLeftSection: false,
    answerBenefitsBottomLeftSection: false,
    answerBenefitsTopRightSection: false,
    answerBenefitsBottomRightSection: false,
    customSalesSectionImage: false,
    howToUseStepsSection: false,
    howToUseTitleSection: false,
    recommendedHeadlineSection: false,
    recommendedBulletsSection: false,
    bulletBenefitsSection: false,

    saleHeadlineSection: false,
    saleSubSections1: false,
    saleSubSections2: false,
    saleSubSections3: false,
    saleSubSections4: false,

    lastSectionImage: false,
    moneybackGuaranteeHeadlineSection: false,
    moneybackGuaranteeTextSection: false,
  });

  useEffect(() => {
    if (productData?.customBadge?.text1
      || productData?.customBadge?.text2
      || productData?.customBadge?.text3
    ) {
      console.log('productData: ', productData);
      setCustomBadgeText(productData?.customBadge)
    }
  }, [productData?.customBadge])

  console.log('sectionsImages_sectionsImages', sectionsImages);

  return isImgCachingLoading ? (
    <div className="spinner-overlay">
      <div className="spinner-container">
        <Spin className="custom-spin" indicator={antIcon} />
      </div>
    </div>
  ) : (
    <div className="product-edit-view-main-container">
      <TopBar
        customBadgeText={customBadgeText}
        setSaveOrPublish={setSaveOrPublish}
        loading={savingLoading}
        setLoading={setSavingLoading}
        shipsFrom={shipsFrom}
        setShipsFrom={setShipsFrom}
        propertiesLength={propertiesLength}
        showSalePrice={showSalePrice}
        productData={productData}
        productPrice={productPrice}
        productImages={productImages}
        sectionsImages={sectionsImages}
        id={id}
        step={step}
        setStep={setStep}
        labelName={labelName}
        setLabelName={setLabelName}
      />
      {step === "Product template" ? (
        <div className="edit-product-content-container ">
          <div className="product-main-left-div ">
            {totalEnabledVariants > totalAllowedVariants ? (
              <Alert
                message={`Enable Variants (${totalEnabledVariants}) exceed the allowed limit (${totalAllowedVariants})`}
                type="error"
                className={"variants-count-error-message"}
              />
            ) : (
              ""
            )}
            <div className="product-main-left-container   ">
              <div className="edit-product-first-section">
                <PrductGallery
                  setProductImages={setProductImages}
                  imageCache={imageCache}
                  images={productImages}
                  galleryImages={sectionClick?.gallery}
                  handleSectionEditChange={handleSectionEditChange}
                />
                <PetsKidsTemplatePriceHeading
                  {...{
                    bulletBenefits: productData.bulletBenefits,
                    testimonialMainSectionText: productData.testimonial,
                    testimonialMainSectionName:
                      productData.testimonialWriters[0].name,
                    mainTestimonialSectionImage:
                      sectionsImages["mainTestimonialSectionImage"],
                  }}
                  customBadgeText={customBadgeText}
                  // customTextData={productData?.customBadge}
                  UploadImage={UploadImage}
                  fileList1={fileList1}
                  setFileList1={setFileList1}
                  previewImage1={previewImage1}
                  setPreviewImage1={setPreviewImage1}
                  fileList2={fileList2}
                  setFileList2={setFileList2}
                  previewImage2={previewImage2}
                  setPreviewImage2={setPreviewImage2}
                  fileList3={fileList3}
                  setFileList3={setFileList3}
                  previewImage3={previewImage3}
                  setPreviewImage3={setPreviewImage3}
                  handleCustomBadgeChange={handleCustomBadgeChange}
                  imageCache={imageCache}
                  shipsFrom={shipsFrom}
                  propertiesLength={propertiesLength}
                  labelName={labelName}
                  setLabelName={setLabelName}
                  variantView={variantView}
                  productName={productData?.productName}
                  shippingLink={productData?.shippingLink}
                  shippingLinkSection={sectionClick?.shippingLink}
                  productSubTitle={productData?.productSubTitle}
                  productNameSection={sectionClick?.productName}
                  featureUnderProductNameSection={
                    sectionClick?.featureUnderProductNameSection
                  }
                  testimonialMainSection={sectionClick?.testimonialMainSection}
                  shippingRiskFreeReturns={sectionClick?.shippingRiskFreeReturns}
                  showSalePrice={showSalePrice}
                  setShowSalePrice={setShowSalePrice}
                  productPrice={productPrice}
                  productPriceSection={sectionClick?.price}
                  guaranteeHeading={productData?.guaranteeHeadline}
                  guaranteeSection={sectionClick?.guaranteeHeadline}
                  guaranteeText={productData?.guaranteeText}
                  handleSectionEditChange={handleSectionEditChange}
                  productVariants={sectionClick?.productVariants}
                  selectedProperty={selectedProperty}
                  setSelectedProperty={setSelectedProperty}
                  tabsSection={sectionClick?.tabsSection}
                >
                  {propertiesLength > 1 ? (
                    <ProductVariantsSection
                      labelName={labelName}
                      setLabelName={setLabelName}
                      variantView={variantView}
                      handleSectionEditChange={handleSectionEditChange}
                      productVariants={sectionClick?.productVariants}
                      selectedProperty={selectedProperty}
                      setSelectedProperty={setSelectedProperty}
                      shipsFrom={shipsFrom}
                    />
                  ) : (
                    ""
                  )}
                </PetsKidsTemplatePriceHeading>
              </div>
              <KidsPetsTemplateSectionUnderPriceAndHeading
                {...{
                  salesTextHeadline1: productData.salesTextHeadline,
                  salesText1: productData?.salesText,
                  secondSectionImage: sectionsImages["secondSectionImage"],
                  handleSectionEditChange: handleSectionEditChange,
                  salesTextHeadline1Section:
                    sectionClick?.salesTextHeadline1Section,
                  salesText1Section: sectionClick?.salesText1Section,
                }}
              />
              <div className="px-[50px]">
                <PetsKidsTemplateBuyersReviews
                  {...{
                    testimonialWriters: productData?.testimonialWriters,
                    testimonialsSectionHeading:
                      productData?.testimonialHeadline,
                    testimonialsSectionSubHeading:
                      productData?.testimonialSubHeadline,
                    testimonialsSectionSubHeading2:
                      productData?.testimonialSubHeadline2,
                    testimonialsName1: productData?.testimonialName2,
                    testimonialsAge1: productData?.testimonialsAge1,
                    testimonialsText1: productData?.testimonial2,
                    testimonialsName2: productData?.testimonialName3,
                    testimonialsAge2: productData?.testimonialsAge2,
                    testimonialsText2: productData?.testimonial3,
                    testimonialsName3: productData?.testimonialName4,
                    testimonialsAge3: productData?.testimonialsAge3,
                    testimonialsText3: productData?.testimonial4,

                    testimonialImage1: sectionsImages?.testimonialImage1,
                    testimonialImage2: sectionsImages?.testimonialImage2,
                    testimonialImage3: sectionsImages?.testimonialImage3,

                    testimonialsHeadingSection:
                      sectionClick?.testimonialsHeadingSection,
                    testimonialsSubHeadingSection:
                      sectionClick?.testimonialsSubHeadingSection,
                    testimonial1Section: sectionClick?.testimonial1Section,
                    testimonial2Section: sectionClick?.testimonial2Section,
                    testimonial3Section: sectionClick?.testimonial3Section,
                    handleSectionEditChange: handleSectionEditChange,
                  }}
                />
              </div>
              <div className="px-[50px]">
                <PetsKidsTemplateDescribeProduct
                  {...{
                    handleSectionEditChange: handleSectionEditChange,
                    questionHeadlineSection:
                      sectionClick?.questionHeadlineSection,
                    answerBenefits: productData?.answerBenefits,
                    questionHeadline: productData?.questionHeadline,
                    answerBenefitsTopLeftSection:
                      sectionClick?.answerBenefitsTopLeftSection,
                    answerBenefitsBottomLeftSection:
                      sectionClick?.answerBenefitsBottomLeftSection,
                    answerBenefitsTopRightSection:
                      sectionClick?.answerBenefitsTopRightSection,
                    answerBenefitsBottomRightSection:
                      sectionClick?.answerBenefitsBottomRightSection,
                    customSalesSectionImage:
                      sectionsImages["customSalesSectionImage"],
                    customSalesImageSection:
                      sectionClick.customSalesSectionImage,
                  }}
                />
              </div>

              <PetsKidsTemplateSteps
                {...{
                  howToUseTitle: productData.howToUseTitle,
                  howToUseSteps: productData.howToUseSteps,

                  howToUseStepsSection: sectionClick?.howToUseStepsSection,
                  howToUseTitleSection: sectionClick?.howToUseTitleSection,
                  handleSectionEditChange: handleSectionEditChange,
                  section8Image: sectionsImages.section8Image,
                  section9Image: sectionsImages.section9Image,
                  section10Image: sectionsImages.section10Image,
                }}
              />
              <PetsKidsTemplateServices
                {...{
                  recommendedHeadline: productData.recommendedHeadline,
                  recommendedBullets: productData.recommendedBullets,
                  recommendedHeadlineSection:
                    sectionClick?.recommendedHeadlineSection,
                  recommendedBulletsSection:
                    sectionClick?.recommendedBulletsSection,
                  handleSectionEditChange: handleSectionEditChange,
                }}
              />

              <PetsKidsTemplateSeventhSection
                {...{
                  saleHeadline: productData.saleHeadline,
                  saleSubSections: productData.saleSubSections,
                  saleHeadlineSection: sectionClick?.saleHeadlineSection,
                  sectionClick: sectionClick,
                  handleSectionEditChange: handleSectionEditChange,
                }}
              />

              <div className="w-full">
                <PetsKidsTemplateFAQ
                  collapseHeadingSection={sectionClick?.collapseHeadingSection}
                  collapseContainerSection={
                    sectionClick?.collapseContainerSection
                  }
                  hanldeCollapseContentClick={hanldeCollapseContentClick}
                  handleSectionEditChange={handleSectionEditChange}
                  FAQData={productData.frequentlyAskedQuestions}
                  setCollapseIndex={setCollapseIndex}
                />
              </div>
              <div className="w-full pb-[50px]">
                <PetsKidsTemplateLastSalesSection
                  {...{
                    moneybackGuaranteeHeadline:
                      productData.moneybackGuaranteeHeadline,
                    moneybackGuaranteeText: productData.moneybackGuaranteeText,
                    moneybackGuaranteeHeadlineSection:
                      sectionClick.moneybackGuaranteeHeadlineSection,
                    moneybackGuaranteeTextSection:
                      sectionClick.moneybackGuaranteeTextSection,
                    lastImageSection: sectionClick.lastSectionImage,
                    lastSectionImage: sectionsImages["lastSectionImage"],
                    handleSectionEditChange: handleSectionEditChange,
                  }}
                />
              </div>
            </div>
          </div>
          <PetsKidsTemplateRightSection
            handleCustomBadgeChange={handleCustomBadgeChange}
            customBadgeText={customBadgeText}
            setCustomBadgeText={setCustomBadgeText}
            // customTextData={productData?.customBadge}
            UploadImage={UploadImage}
            fileList1={fileList1}
            setFileList1={setFileList1}
            previewImage1={previewImage1}
            setPreviewImage1={setPreviewImage1}
            fileList2={fileList2}
            setFileList2={setFileList2}
            previewImage2={previewImage2}
            setPreviewImage2={setPreviewImage2}
            fileList3={fileList3}
            setFileList3={setFileList3}
            previewImage3={previewImage3}
            setPreviewImage3={setPreviewImage3}
            imageCache={imageCache}
            showSalePrice={showSalePrice}
            setShowSalePrice={setShowSalePrice}
            productPrice={productPrice}
            setProductPrice={setProductPrice}
            productData={productData}
            updateProductData={setProductData}
            sectionClick={sectionClick}
            setSectionClick={setSectionClick}
            handleSectionEditChange={handleSectionEditChange}
            editsectionImageUrl={editsectionImageUrl}
            setEditsectionImageUrl={setEditsectionImageUrl}
            sectionsImages={sectionsImages}
            setSectionsImages={setSectionsImages}
            productImages={productImages}
            setProductImages={setProductImages}
            aliexpressImages={aliexpressImages}
            setVariantViewChange={setVariantViewChange}
            variantView={variantView}
            productVariants={product_variants}
            shipsFrom={shipsFrom}
            textOnImageDetails={textOnImageDetails}
            setTextOnImageDetails={setTextOnImageDetails}
            FAQData={productData.frequentlyAskedQuestions}
            // setFAQData={setFAQData}
            collapseIndex={collapseIndex}
          />
        </div>
      ) : step === "Variant pricing" ? (
        <VariantPricingTable
          imageCache={imageCache}
          shipsFrom={shipsFrom}
          setShipsFrom={setShipsFrom}
          groupBy={groupBy}
          setGroupBy={setGroupBy}
          labelName={labelName}
          setLabelName={setLabelName}
          productPrice={productPrice}
        />
      ) : (
        <ShippingConfigurationDetails shipsFrom={shipsFrom} />
      )}

      <SavePublishModal
        OpenModal={savingLoading}
        saveOrPublish={saveOrPublish}
      />
    </div>
  );
};

export default PetsKidsTemplateEditView;
const CustomLiquid = () => {
  return (
    <div class="custom-slider-main-container-2">
      <div class="page-width-slider">
        <div class="custom-slider-type-2-content">
          <h2>Stop Compose: A Structured Path</h2>
        </div>
        <div
          class="custom-slider-type-2-container-2"
        // style={{ transform: "translateX(0%)" }}
        >
          <div
            class="testimonial"
            id="testimonial-testimonial_dg9WJQ"
            style={{ flex: "0 0 33.3333% !important" }}
          >
            <div class="custom-slider-type-2-inner">
              <img
                src="//sibr60mujk9m04ek-61932142812.shopifypreview.com/cdn/shop/files/step1.png?v=1730184315&width=200"
                alt
                width={200}
                height={200}
                loading="lazy"
              />
              <div class="user-info">
                <div class="user-details">
                  <h3 class="name-title">John Doe</h3>
                </div>
              </div>
              <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p>
            </div>
          </div>
          <div
            class="testimonial"
            id="testimonial-testimonial_4iJyJx"
            style={{ flex: "0 0 33.3333% !important" }}
          >
            <div class="custom-slider-type-2-inner">
              <img
                src="//sibr60mujk9m04ek-61932142812.shopifypreview.com/cdn/shop/files/step2.png?v=1730184337&width=200"
                alt
                srcSet="//sibr60mujk9m04ek-61932142812.shopifypreview.com/cdn/shop/files/step2.png?v=1730184337&width=200 200w"
                width={200}
                height={200}
                loading="lazy"
              />
              <div class="user-info">
                <div class="user-details">
                  <h3 class="name-title">John Doe</h3>
                </div>
              </div>
              <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p>
            </div>
          </div>
          <div
            class="testimonial"
            id="testimonial-testimonial_dHBga7"
            style={{ flex: "0 0 33.3333% !important" }}
          >
            <div class="custom-slider-type-2-inner">
              <img
                src="//sibr60mujk9m04ek-61932142812.shopifypreview.com/cdn/shop/files/step3.png?v=1730184357&width=200"
                alt
                srcSet="//sibr60mujk9m04ek-61932142812.shopifypreview.com/cdn/shop/files/step3.png?v=1730184357&width=200 200w"
                width={200}
                height={200}
                loading="lazy"
              />
              <div class="user-info">
                <div class="user-details">
                  <h3 class="name-title">John Doe</h3>
                </div>
              </div>
              <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
