import React, { useEffect, useState } from "react";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  useSearchParams,
} from "react-router-dom";
import {
  Heading,
  useAuthenticator,
  View,
  withAuthenticator,
} from "@aws-amplify/ui-react";
import Dashboard from "./Components/Dashboard";
import ManageOrders from "./Components/ManageOrders/ManageOrders";
import MyProducts from "./Components/MyProducts/MyProducts";
import ProductEditViewLoading from "./Components/ProductEditView/ProductEditViewLoadingScreen";
import ImportingLoading from "./Components/ProductEditView/ImportingProductLoading";
import CopyRightPage from "./Components/CopyRight/CopyRightPage";
import currentAuthenticatedUser, {
  getCurrentAuthenticatedSession,
} from "./authUser";
import { URLS } from "./URLS";
import { message, Typography } from "antd";
import { useDispatch } from "react-redux";
import { setStoreInformation } from "./redux/slices/auth-user-slice";
import ProTemplate from "./Components/ProTemplate/ProTemplate";
import Subscriptions from "./Components/Subscriptions/Subscriptions";
import logo from "./assets/sofia-logo-transparent.png";
import wind from "./assets/wind.svg";
import lightning from "./assets/lightning.svg";
import graph from "./assets/arrow-up-graph.svg";
import "./App.css";

const App = () => {
  return (
    <Router>
      <Routes>
        {/* Public Route */}
        <Route path="/privacy" element={<CopyRightPage />} />
        {/* Protected Routes */}
        <Route path="/*" element={<AuthPage />} />
      </Routes>
    </Router>
  );
};

const components = {
  SignIn: {
    Header() {
      return (
        <Heading
          style={{textAlign: 'center'}}
          level={3}
        >
          Sign in
        </Heading>
      );
    },
    Footer() {
      const {toForgotPassword} = useAuthenticator();

      return (
        <View textAlign="center">
          <Typography className="forgot-password" onClick={toForgotPassword}>
            Forgot your password?
          </Typography>
        </View>
      );
    },
  },

  SignUp: {
    Header() {
      return (
        <Heading
          style={{textAlign: 'center'}}
          level={3}
        >
          Join Sofia today
        </Heading>
      );
    },
    Footer() {
      return (
        <></>
      );
    },
  },

  ForgotPassword: {
    Header() {
      return (
        <Heading
          style={{textAlign: 'center'}}
          level={3}
        >
          Forgotten password
        </Heading>
      );
    },
    Footer() {
      <></>;
    },
  },

  ConfirmResetPassword: {
    Header() {
      return (
        <Heading
          style={{textAlign: 'center'}}
          level={3}
        >
          Password reset
        </Heading>
      );
    },
    Footer() {
      return <></>;
    },
  },
};

const formFields = {
  signIn: {
    username: {
      placeholder: "Email",
      isRequired: true,
      label: false,
    },
    password: {
      placeholder: "Password",
      isRequired: true,
      label: false,
    },
  },
  signUp: {
    email: {
      placeholder: "Email",
      label: false,
      isRequired: true,
    },
    password: {
      placeholder: "Password",
      label: false,
      isRequired: true,
    },
    confirm_password: {
      placeholder: "Confirm password",
      label: false,
      isRequired: true,
    },
  },
  forgotPassword: {
    username: {
      label:
        "Enter your email address and we will send you a link to reset your password",
      placeholder: "Enter email",
    },
  },
  confirmResetPassword: {
    confirmation_code: {
      placeholder: "Enter your Confirmation Code",
      label: false,
      isRequired: true,
    },
    password: {
      placeholder: "New password",
      label: false,
      isRequired: true,
    },
    confirm_password: {
      placeholder: "Confirm new password",
      label: false,
      isRequired: true,
    },
  },
};

const Block = ({icon, headerText, text}) => {
  return (
    <div className="block-body">
      <div className="block-body-header">
        <img src={icon} alt={headerText}/>
        <Typography className="block-header-text">
          {headerText}
        </Typography>
      </div>
      <Typography className="block-body-text">{text}</Typography>
    </div>
  );
};

const AuthPage = () => {
  const {authStatus, route} = useAuthenticator((context) => [context.user]);

  if (authStatus === 'unauthenticated' || route === 'signIn') {
    return (
      <div style={{display: "flex", flexDirection: "row"}}>
        <div className="auth-left-block">
          <div style={{padding: "56px"}}>
            <img src={logo} alt="logo"/>
          </div>
          <div className="description-block">
            <Typography color="white" className="description-block-first-row">Your effortless dropshipping
              workflow</Typography>
            <div style={{display: "flex", flexDirection: "row", justifyContent: "space-between"}}>
              <Block
                icon={wind}
                headerText={"Move fast"}
                text={
                  "Import products from AliExpress, including all product images. Speed up your product testing."
                }
              />
              <Block
                icon={lightning}
                headerText={"Build better"}
                text={
                  "Ready-to-launch dropshipping product pages with proven to convert custom templates."
                }
              />
              <Block
                icon={graph}
                headerText={"Stay ahead"}
                text={
                  "Fulfill all orders automatically on AliExpress. Make order fulfillment and tracking a breeze."
                }
              />
            </div>
          </div>
        </div>
        <div style={{width: '50%'}}>
          <AuthenticatedApp/>
        </div>
      </div>
    )
  }

  if (authStatus === 'configuring') {
    return <div style={{position: 'absolute', backgroundColor: 'white', width: '100%', height: '100%'}}/>
  }

  return <AuthenticatedApp/>
}

// Wrap only the authenticated routes with withAuthenticator
const AuthenticatedApp = withAuthenticator(
  () => {
    const [searchParams] = useSearchParams();
    const [subscriptionStatus, setSubscriptionStatus] = useState(null);
    const [authData, setAuthData] = useState(null);

    const dispatch = useDispatch();
    useEffect(() => {
      const checkShopifyConnection = async () => {
        let shopParam = searchParams.get("shop");
        let data
        if (!shopParam) {
          shopParam = "";
        }
        const { userId, loginId } = await currentAuthenticatedUser(); // Await to get the resolved value
        const accessToken = await getCurrentAuthenticatedSession();
        try {
          const response = await fetch(
            `${
              URLS.BACKEND_API
            }/store/auth_check?shop=${shopParam}&userId=${userId}&cognito_email=${encodeURIComponent(
              loginId
            )}`,
            {
              method: "GET",
              headers: userId
                ? {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${accessToken}`, // Replace with your auth token format
                  }
                : {
                    "Content-Type": "application/json",
                  },
            }
          );
           data = await response.json();
           setAuthData(data)
          localStorage.setItem("email", data?.storeData?.email);
          localStorage.setItem("shop_owner", data?.storeData?.shop_owner);
          localStorage.setItem("shopifyConnected", data.connected);
          localStorage.setItem("shopName", data.storeData.shop);

          // saving the shops owner and email in redux store
          const shopifyConnectionDetails = {
            connected: data?.connected,
            name: data?.storeData.shop,
          };

          dispatch(
            setStoreInformation({
              shop_owner: data?.storeData?.shop_owner,
              email: data?.storeData?.email,
              shopifyConnectionDetails,
            })
          );

          if (data.token) {
            localStorage.setItem("token", data?.token);
          }

          if (data.connected) {
            localStorage.setItem("shopName", data?.storeData.shop);
            localStorage.setItem("shopId", data?.storeData.shop_id);
          } else if (data?.error) {
          } else {
          }
        } catch (error) {
          message.error(
            "Unexpected error occured while getting Shopify details!"
          );
        } finally {
          if (subscriptionStatus?.status === false && data?.connected === true) {
            console.log("Please reload!");
            window.onbeforeunload = function () {
              return "Data will be lost if you leave the page, are you sure?";
            };
          }
        }
      };

      checkShopifyConnection();
      // checkAliExpressConnection();

      // if (subscriptionStatus?.status === false && data?.connected === true) {
      //   console.log("Please reload!");
      //   window.onbeforeunload = function () {
      //     return "Data will be lost if you leave the page, are you sure?";
      //   };
      // }
      
    }, []);
    console.log("subscriptionStatus?.status",subscriptionStatus?.status, authData?.connected);

    useEffect(() => {
      if (subscriptionStatus?.status === false && authData?.connected === true) {
        console.log("Please reload!");
        window.onbeforeunload = function () {
          return "Data will be lost if you leave the page, are you sure?";
        };
      }
    }, [subscriptionStatus, authData]);
    return (
      <Routes>
        <Route
          path="/"
          element={
            <MyProducts
              subscriptionStatus={subscriptionStatus}
              setSubscriptionStatus={setSubscriptionStatus}
              authData={authData}
            />
          }
        />
        <Route path="/settings" element={<Dashboard />} />
        <Route path="/subscriptions/:status?" element={<Subscriptions />} />
        <Route path="/orders" element={<ManageOrders />} />
        <Route path="/edit-product/:id" element={<ProductEditViewLoading />} />
        <Route path="/pro-template/:id" element={<ProTemplate />} />
        <Route path="/edit-products/:id" element={<ImportingLoading />} />
      </Routes>
    );
  },
  { components: components, formFields: formFields }
);

export default App;
