import React, { useEffect, useState } from "react";
import { DownOutlined } from "@ant-design/icons";
import { Dropdown, message, Modal, Button, Space } from "antd";

import modaldownloadicon from "../../../assets/modaldownloadicon.svg";
import { callBackendAPI } from "../../../helpers/apiCalls";
import { useNavigate } from "react-router-dom";
import {
  setProductDataInState,
  resetProductDataInState,
  setEditViewStyle,
} from "../../../redux/slices/product-variants-slice";
import { useDispatch } from "react-redux";
import { setCountryMethod } from "../../../redux/slices/shipping-config-slice";
import CustomLoader from "../../CustomLoader";
import { setTemplateView } from "../../../redux/slices/template-slice";
import { URLS } from "../../../URLS";
import TemplateSelector from "./TemplateSelector";

const AddProductModal = ({
  OpenModal,
  setOpModal,
  fetchProducts,
  handleOldIdsRecord,
  showtemplates,
  subscriptionStatus,
}) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [importButtonLoading, setImportButtonLoading] = useState(false);
  const [productLink, setProductLink] = useState("");
  const [tone, setTone] = useState("");
  const [selectedLanguage, setSelectedLanguage] = useState("English");
  const [formwarning, setFormwarning] = useState("");
  const [successMessage, setSuccessMessage] = useState("");
  const [template, setTemplate] = useState(null);

  useEffect(() => {
    setProductLink("");
    setFormwarning("");

    if (document.getElementById("link-input")) {
      document.getElementById("link-input").focus();
    }
    const modal = document.querySelector(".ant-modal-content");
    if (modal) {
      modal.setAttribute("style", "padding: 20px !important;");
    }
  }, [OpenModal]);

  useEffect(() => {
    const handleBeforeUnload = (e) => {
      if (importButtonLoading) {
        e.preventDefault();
        e.returnValue = "";
      }
    };
  
    window.addEventListener("beforeunload", handleBeforeUnload);
  
    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, [importButtonLoading]);

  const ConfirmButton = () => {
    return (
      <span>
        {!importButtonLoading ? (
          "Import product"
        ) : (
          <span className="flex justify-center items-center gap-2">
            <p>Importing product</p>
            <CustomLoader width="20px" />
          </span>
        )}
      </span>
    );
  };

  const handleCancel = () => {
    setOpModal(false);
    setSuccessMessage("");
    setProductLink("");
    setFormwarning("");
    setTemplate(null);
  };

  // importProductHandler
  const importProductHandler = async () => {
    //calling api
    try {
      const response = await callBackendAPI(
        "aliexpress/v1_fetch_product",
        "GET",
        {
          url: productLink,
          templateType: template.value,
          tone: tone,
          language: selectedLanguage,
        }
      );

      if (!response?.error && response?.status) {
        handleOldIdsRecord();
        setProductLink("");
        setFormwarning("");

        const { data } = response;
        const {
          productTemplate,
          product_variants,
          shippingConfiguration,
          variantsArrangedObject,
        } = data;
        const { variantDisplayStyle } = productTemplate;

        console.log("variantsArrangedObject : ", variantsArrangedObject);

        dispatch(resetProductDataInState({}));

        dispatch(
          setCountryMethod(
            shippingConfiguration
              ? shippingConfiguration
              : [{ id: 0, country: "", method: [], issue: null }]
          )
        );
        dispatch(
          setProductDataInState(
            response
              ? {
                  ...response,
                  variantsArrangedObject: variantsArrangedObject
                    ? variantsArrangedObject
                    : {},
                }
              : {}
          )
        );
        //
        dispatch(setTemplateView(response.templateType));
        // edit view style
        if (variantDisplayStyle !== undefined && product_variants.length > 1) {
          const val = variantDisplayStyle === "button" ? "Pills" : "Dropdown";
          dispatch(setEditViewStyle(val));
        } else {
          dispatch(setEditViewStyle("Dropdown"));
        }
        navigate(`/edit-products/${response?.product_id}`);

        // Close the modal after 2 seconds
        setTimeout(() => {
          fetchProducts();
          setSuccessMessage("");
          setOpModal(false);
        }, 1000);
      } else {
        setFormwarning(response.message);
      }
    } catch (error) {
      setFormwarning(error.message);
      console.error("Error:", error);
    } finally {
      setImportButtonLoading(false);
    }
  };
  // check if the user selects the pro template and eligible for it
  const checkValidityOfProTemplate = async () => {
    try {
      const endpoint = "store/check_subscription_status/";
      const response = await callBackendAPI(endpoint, "GET", {}, {});
      if (response?.status) {
        if (response.redirect === true && !response.override) {
          message.error("Choose your plan first");
          navigate("/subscriptions");
        } else {
          importProductHandler();
        }
      } else {
        console.log("error while selecting plan", response);
        setImportButtonLoading(false);
      }
    } catch (error) {
      setImportButtonLoading(false);

      message.error("something went wrong api failed!", error.message);
      console.log("error while selecting plan", error);
      // setLoading(false);
    }
  };

  const handleConfirm = async () => {
    if (!productLink) {
      setFormwarning("Please enter url of product");
    } else if (!template) {
      message.error("Please select a template");
    } else {
      setFormwarning("");
      setImportButtonLoading(true);
      if (template.value != "default") {
        checkValidityOfProTemplate();
      } else {
        importProductHandler();
      }
    }
  };

  const handleKeyPress = (e) => {
    if (e.key === "Enter") {
      e.preventDefault();
      handleConfirm();
    }
  };

  const handleMenuClick = (e) => {
    setSelectedLanguage(e.key);
  };

  const languageDropdownProps = {
    items: [
      {
        key: "English",
        label: "English",
      },
      {
        key: "Spanish",
        label: "Spanish",
      },
    ],
    onClick: handleMenuClick,
  };

  return (
    <div style={{ position: "relative" }} className="add-product-modal-wrapper">
      
      <Modal
        closable={false}
        open={OpenModal}
        onOk={handleConfirm}
        onCancel={handleCancel}
        okText={<ConfirmButton />}
        cancelText="Cancel"
        okButtonProps={{
          className: importButtonLoading
            ? "modal-import-button modal-import-button-disabled"
            : "modal-import-button",
          disabled: importButtonLoading,
        }}
        cancelButtonProps={{
          className: importButtonLoading
            ? "product-modal-cancel-button-disabled"
            : "product-modal-cancel-button",
          disabled: importButtonLoading,
        }}
        maskClosable={!importButtonLoading}
        width={1300}
        className="add-product-modal !h-[70vh]"
      >
        {importButtonLoading && <div className="fake-loading-bar"></div>}
        <div className="add-product-modal-main-container !h-[70vh] !overflow-y-scroll !overflow-x-hidden">
          <div className="flex flex-row items-start gap-4">
            <img src={modaldownloadicon} alt="modaldownloadicon" />
            <div>
              <div className="modal-content-container">
                <h4>Import product</h4>
                <p>
                  Paste the link of the AliExpress product you want to import.
                </p>
              </div>
              <form
                style={{ gap: "0px" }}
                onSubmit={handleConfirm}
                className="input-form"
              >
                <input
                  id="link-input"
                  value={productLink}
                  onChange={(e) => setProductLink(e.target.value)}
                  style={{ marginTop: "8px" }}
                  className="form-control"
                  type="text"
                  placeholder="https://aliexpress.com/my-product"
                  onKeyDown={handleKeyPress}
                />
                {successMessage && (
                  <span
                    className="add-product-modal-success "
                    style={{ color: "green" }}
                  >
                    {successMessage}
                  </span>
                )}
                {formwarning ? (
                  <span className="add-product-modal-warning  ">
                    {formwarning}
                  </span>
                ) : null}
                <span className="mt-[16px] font-medium">
                  Describe the sales angle for this product
                </span>
                <input
                  id="link-input"
                  value={tone}
                  onChange={(e) => setTone(e.target.value)}
                  style={{ marginTop: "8px" }}
                  className="form-control mb-[16px]"
                  type="text"
                  placeholder="sales angle"
                  onKeyDown={handleKeyPress}
                />
                <span className="mb-[10px] font-medium">Select language</span>
                <Dropdown
                  menu={languageDropdownProps}
                  trigger={["click"]}
                  className="language-selector-dropdown mb-[16px]"
                >
                  <Button>
                    <Space>
                      {selectedLanguage}
                      <DownOutlined />
                    </Space>
                  </Button>
                </Dropdown>
              </form>
            </div>
          </div>
          <TemplateSelector
            subscriptionStatus={subscriptionStatus}
            template={template}
            setTemplate={setTemplate}
          />
        </div>
      </Modal>
    </div>
  );
};

export default AddProductModal;
/*const TemplateSelectionButtons = ({ template, setTemplate }) => {
  const defaultTemplateLabel = <p>Default template</p>;
  const ProTemplateLabel = (
    <div className="flex w-full justify-between items-center">
      <p>Pro template</p>
      <span class=" w-6 h-6 flex items-center text-purple-600 icon-bg-light-blue justify-center ">
        <svg
          stroke="currentColor"
          fill="currentColor"
          stroke-width="0"
          viewBox="0 0 640 512"
          height="1em"
          width="1em"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path d="M528 448H112c-8.8 0-16 7.2-16 16v32c0 8.8 7.2 16 16 16h416c8.8 0 16-7.2 16-16v-32c0-8.8-7.2-16-16-16zm64-320c-26.5 0-48 21.5-48 48 0 7.1 1.6 13.7 4.4 19.8L476 239.2c-15.4 9.2-35.3 4-44.2-11.6L350.3 85C361 76.2 368 63 368 48c0-26.5-21.5-48-48-48s-48 21.5-48 48c0 15 7 28.2 17.7 37l-81.5 142.6c-8.9 15.6-28.9 20.8-44.2 11.6l-72.3-43.4c2.7-6 4.4-12.7 4.4-19.8 0-26.5-21.5-48-48-48S0 149.5 0 176s21.5 48 48 48c2.6 0 5.2-.4 7.7-.8L128 416h384l72.3-192.8c2.5.4 5.1.8 7.7.8 26.5 0 48-21.5 48-48s-21.5-48-48-48z"></path>
        </svg>
      </span>
    </div>
  );
  const KidsAndPetsTemplateLabel = (
    <div className="flex w-full justify-between items-center">
      <p>Kids & Pets template</p>
      <span class=" w-6 h-6 flex items-center text-purple-600 icon-bg-light-blue justify-center ">
        <svg
          stroke="currentColor"
          fill="currentColor"
          stroke-width="0"
          viewBox="0 0 640 512"
          height="1em"
          width="1em"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path d="M528 448H112c-8.8 0-16 7.2-16 16v32c0 8.8 7.2 16 16 16h416c8.8 0 16-7.2 16-16v-32c0-8.8-7.2-16-16-16zm64-320c-26.5 0-48 21.5-48 48 0 7.1 1.6 13.7 4.4 19.8L476 239.2c-15.4 9.2-35.3 4-44.2-11.6L350.3 85C361 76.2 368 63 368 48c0-26.5-21.5-48-48-48s-48 21.5-48 48c0 15 7 28.2 17.7 37l-81.5 142.6c-8.9 15.6-28.9 20.8-44.2 11.6l-72.3-43.4c2.7-6 4.4-12.7 4.4-19.8 0-26.5-21.5-48-48-48S0 149.5 0 176s21.5 48 48 48c2.6 0 5.2-.4 7.7-.8L128 416h384l72.3-192.8c2.5.4 5.1.8 7.7.8 26.5 0 48-21.5 48-48s-21.5-48-48-48z"></path>
        </svg>
      </span>
    </div>
  );

  const handleChange = (value) => {
    console.log(`selected ${value}`);
    setTemplate(value);
  };
  return (
    <div className="flex flex-col ">
      <span class="modal-input-label mb-[8px]">Select template</span>
      <Select
        defaultValue="kids"
        style={{ width: 220, height: "44px" }}
        onChange={handleChange}
        options={[
          { value: "default", label: defaultTemplateLabel },
          { value: "pro", label: ProTemplateLabel },
          { value: "kids", label: KidsAndPetsTemplateLabel },
        ]}
      />
    </div>
  );
};*/
