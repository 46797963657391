import PetsKidsMainTestimonialImageEdit from "./PetsKidsMainTestimonialImageEdit";

const PetsKidsTestimonialsComponent = ({
  handleInputChange,
  testimonialsName,
  testimonialsAge,
  testimonialsText,
  updateProductData,
  index,
  image,
  setSectionsImages,
  isCanvaConnected,
  awaitingRefresh,
  setAwaitingRefresh,
  isEditCanvaLoading,
  setIsEditCanvaLoading,
  toWhich,
}) => {
  return (
    <>
      <div className="title-container">Image Content</div>

      <div className="input-container">
        <label className="input-label">Edit Content</label>
        <PetsKidsMainTestimonialImageEdit
          image={image}
          setProductImages={setSectionsImages}
          isCanvaConnected={isCanvaConnected}
          awaitingRefresh={awaitingRefresh}
          setAwaitingRefresh={setAwaitingRefresh}
          isEditCanvaLoading={isEditCanvaLoading}
          setIsEditCanvaLoading={setIsEditCanvaLoading}
          imageIndex={0}
          toWhich={toWhich}
        />

        <div className="input-container !px-0">
          <label className="input-label">Name</label>
          <input
            className="form-input"
            type="text"
            value={testimonialsName}
            onChange={(e) =>
              updateProductData((prev) => {
                const newTestimonials = [...prev.testimonialWriters];
                newTestimonials[index - 1] = {
                  ...newTestimonials[index - 1],
                  name: e.target.value,
                };
                return { ...prev,testimonialWriters:newTestimonials};
              })
            }
          />
          <label className="input-label">Age</label>
          <input
            className="form-input"
            type="text"
            value={testimonialsAge}
            onChange={(e) =>
                updateProductData((prev) => {
                  const newTestimonials = [...prev.testimonialWriters];
                  newTestimonials[index - 1] = {
                    ...newTestimonials[index - 1],
                    age: e.target.value,
                  };
                  return { ...prev,testimonialWriters:newTestimonials};
                })
              }
          />
        </div>
        <div className="input-container !px-0">
          <label className="input-label">Details</label>
          <textarea
            rows="6"
            className="form-input"
            value={testimonialsText}
            onChange={(e) =>
              handleInputChange(`testimonial${index}`, e.target.value)
            }
          />
        </div>
      </div>
    </>
  );
};
export default PetsKidsTestimonialsComponent;
