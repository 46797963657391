

export default function checkPriceDifference(saleOrPrice, record) {
    
    // const removeKeys = ['Price', 'SalePrice', 'key', 'children','status'];
    const { children } = record
    let difference, high, low;

    if (children) {
        
    if (saleOrPrice === 'price') {
        
        const prices = children.map(item => item.status == "active" ? item?.Price  : 0);
        
        const nonZeroPrices = prices.filter(Price => Price !== 0);
        if (nonZeroPrices.length > 0) {
            low = Math.min(...nonZeroPrices);
            if (low == 0 && nonZeroPrices.some(item => item && typeof item === 'string' && item.includes('.'))) {
                const decimalPlaces = nonZeroPrices.find(item => item && typeof item === 'string' && item?.includes('.')).split('.')[1]?.length || 0;

                low = low.toFixed(decimalPlaces);  
            }
        } else {
            low = 0; // or Math.min(...prices) if you want to include 0 as the minimum when all values are zero
        }

        high = Math.max(...prices);
    }
   else if (saleOrPrice === 'grossProfit') {
        const gross = children.map(item => item.status == "active" ? item?.grossProfit  : 0);
        
        const nonZeroPrices = gross.filter(Price => Price !== 0);
        if (nonZeroPrices.length > 0) {
            low = Math.min(...nonZeroPrices);
        } else {
            low = 0; // or Math.min(...prices) if you want to include 0 as the minimum when all values are zero
        }

        high = Math.max(...gross);
    }
    else {
       
        // const prices = children.map(item => 
        //     item.status == "active" ? (item?.SalePrice.includes('.') === 0.0 ? 0.0 : item?.SalePrice || 0) : 0
        //   );
        const prices = children.map(item => item.status == "active" ? item?.SalePrice  : 0);

        const nonZeroPrices = prices.filter(SalePrice => SalePrice !== 0);


        if (nonZeroPrices.length > 0) {
            low = Math.min(...nonZeroPrices);
            if (low == 0 && nonZeroPrices.some(item => item && typeof item === 'string' && item.includes('.'))) {
                const decimalPlaces = nonZeroPrices.find(item => item && typeof item === 'string' && item?.includes('.')).split('.')[1]?.length || 0;

                low = low.toFixed(decimalPlaces);  
            }
           
        } else {
            low = 0; // or Math.min(...prices) if you want to include 0 as the minimum when all values are zero
            
        }

        high = Math.max(...prices);
       
    }
    difference = low === high ? false : true
}
    
    return { difference, high, low }
}