import { signOut } from "aws-amplify/auth";
import { message } from "antd";

export const handleLogout = async () => {
  try {
    await signOut();
    localStorage.removeItem("token");
    localStorage.removeItem("shopName");
    localStorage.removeItem("shopId");
    localStorage.removeItem("email");
    localStorage.removeItem("shop_owner");
    localStorage.removeItem("shopifyConnected");
    localStorage.removeItem("shopName");
    window.location.href = "/";
  } catch (error) {
    console.error("Error logging out:", error);
    message.error(`cannot logout due to: ${error?.mess}`, 2);
  }
};
